import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import CurrentBooked from './currentbooked'
import { withStyles } from '@material-ui/core/styles';
import './offer.css'
import ActionPanTool from 'material-ui/svg-icons/action/pan-tool';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});


const allwp ={
  // width : '90%',
  display : 'flex',
  justifyContent : 'space-between',
  // marginLeft : 'auto',
  // marginRight : 'auto'

}

const notallowedstyle = {
  width : '85px',
  textAlign : 'center',
  position: 'relative',
  top: '23%',
}

const cancelstyle = {
  fontSize: '40px',
  color: '#947070',
}



class OfferPopup extends React.Component {
  	constructor(props){
  	  super(props);

      this.state = {
        offer_data : {},
        comment : ''
      }

  }

  componentDidMount () {
    this.setState({offer_data : this.props.current_offers})
    let comment = ""
    let x = this.props.current_offers
    comment = Object.keys(x).map((k,i) => x[k].comment && x[k].comment ).join('')
    this.setState({'comment': comment})
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.setState({offer_data : this.props.current_offers})
      let comment = ""
      let x = this.props.current_offers
      comment = Object.keys(x).map((k,i) => x[k].comment && x[k].comment ).join('')

      this.setState({'comment': comment})
    }
  }

handleClose = () => {
  this.props.onCancel(true);
};

 handleChange = (event,wp,shift) => {
   console.log(wp+' - '+shift+' - '+event.target.checked);
   let offer_data = {...this.state.offer_data}
   if (!(wp in offer_data)) offer_data[wp] = {}
   offer_data[wp][shift]  = event.target.checked;
   this.setState({offer_data})
   console.log('changes');
 }

handleClick(event){

this.props.onOffer(this.state.offer_data,this.state.comment);

}


render() {
  if (this.props.allowed_wp && this.props.offer_day !== 0) {
  var checkboxes = this.props.allowed_wp.map((wp,index) => {
    return (
      <Paper className="shiftchoose" key={index}>

          {wp.replace('_',' ')}

          <Divider/>
        {/* {this.props.cert[0] && !this.props.mon_conf[wp].offersblocked ? */}
        {!this.props.mon_conf[wp].offersblocked ?
          <div className="shiftcheckboxes">
        <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 40 }}/>}
                checkedIcon={<CheckBoxIcon style={{ fontSize: 40 }}/>}
                checked = {((wp in this.state.offer_data) && (this.state.offer_data[wp].TD)) || false}
                onChange={(event) => this.handleChange(event,wp,'TD')}
                value="TD"
                color="primary"
                disabled = {('booked' in this.state.offer_data &&  'TD' in this.state.offer_data.booked && this.state.offer_data.booked.TD == wp)  }
              />
            }
            label="TD"
          />
        <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 40 }} />}
                checkedIcon={<CheckBoxIcon style={{ fontSize: 40 }}/>}
                checked = {((wp in this.state.offer_data) && (this.state.offer_data[wp].ND)) || false}
                onChange={(event) => this.handleChange(event,wp,'ND')}
                value="ND"
                color="primary"
                disabled = {'booked' in this.state.offer_data && 'ND' in this.state.offer_data.booked &&  this.state.offer_data.booked.ND == wp}
              />
            }
            label="ND"   />
            </div>
          : <div style={notallowedstyle}>{this.props.cert[0] ? <LockIcon style={cancelstyle}/> : <>Impfzertifikat fehlt</>}</div>
        }
    </Paper>
  )})
}
  return (
    <div>
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title"> {this.props.wt}, {this.props.datum}</DialogTitle>
        <DialogContent>
        <CurrentBooked allowed_wp={this.props.allowed_wp} mon_conf={this.props.mon_conf} currentset={this.props.current_set} socket={this.props.socket} dayorgl={this.props.dayorgl}/>
        <div style={allwp}>
        {checkboxes}
        </div>
          <TextField
              fullWidth
             label="Bemerkungen"
             multiline
             value = {this.state.comment}
             onChange = {(e) => this.setState({'comment':e.target.value})}
           />
         <br/>
          </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" primary='true' onClick={(event) => this.handleClose()}>
            Abbrechen
          </Button>
           <Button variant="contained" color="primary" primary='true' onClick={(event) => this.handleClick(event)}>
           Absenden
           </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

}

OfferPopup.propTypes = {
  allowed_wp: PropTypes.array.isRequired,
  current_offers: PropTypes.object.isRequired
};

export default OfferPopup;
